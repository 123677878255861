import React, { useLayoutEffect, useState, useRef } from "react";
import {
  CardMedia,
  Link,
  Typography,
  Grid,
  Box,
  IconButton,
  Fade,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function Poduct_List_View(props) {
  const { component } = props;

  const { grid = 3, xs = 6 } = component.props;

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const product = component.getData("default.product", null);

  const {
    slug = null,
    name = null,
    group = null,
    price = null,
    first_price = null,
    brand = "",
    type = "",
  } = devHelper.getObjectValue(product, "_values", {});

  const wishlist = component.getData("default.wishlist", false);

  //Add or Remove product from Wishlist
  const updateWishList = (boolean) => {
    boolean
      ? component.addToWishList(slug)
      : component.removeFromWishList(slug);

    component.setData("default.wishlist", true);
  };

  const ready = component.getData("default.ready", false);

  const targetRef = useRef();

  function SetImageHeight() {
    const [height, setHeight] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        if (targetRef.current) {
          setHeight(732 * (targetRef.current.clientWidth / 732));
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return height;
  }

  const img_height = SetImageHeight();

  return (
    <Grid item md={grid} xs={xs}>
      <Box ref={targetRef}>
        {product && img_height > 0 && (
          <Box
            sx={{
              height: "100%",
              pt: { xs: "0px", md: "4px" },
              pb: { xs: "0px", md: "12px" },
              transition: "all .3s ease-in-out",
              webkitTransition: "all .3s ease-in-out",
              "&:hover": {
                pt: { xs: 0, md: 0 },
                pb: { xs: 0, md: 2 },
                "& #child": {
                  boxShadow: {
                    xs: "none",
                    md: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                  },
                },
              },
            }}
          >
            <Box
              id="child"
              sx={{
                position: "relative",
              }}
            >
              <IconButton
                disableRipple
                onClick={(e) => {
                  updateWishList(!wishlist);
                }}
                sx={{
                  position: "absolute",
                  top: 10,
                  borderRadius: "50%",
                  bgcolor: "#fff",
                  color: "#000",
                  right: { xs: 10, sm: 15 },
                  fontSize: { xs: "16px", sm: "18px" },
                  zIndex: 1,
                }}
                aria-label="Add to Wishlist"
              >
                <i
                  className={
                    wishlist ? "fa-solid fa-heart" : "fa-light fa-heart"
                  }
                />
              </IconButton>
              <Link
                component={RouterLink}
                to={`/product/${slug}`}
                title={`${devHelper.getObjectValue(
                  brand,
                  "item._values.name"
                )} - ${name}`}
                underline="none"
              >
                <Box
                  sx={{
                    width: "100%",
                    height: img_height,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#F7F8FA",
                  }}
                >
                  <Fade in={ready}>
                    <Box
                      sx={{
                        overflow: "hidden",
                      }}
                    >
                      <CardMedia
                        sx={{
                          maxWidth: "100%",
                          objectFit: "contain",
                          height: { xs: img_height },
                        }}
                        component="img"
                        image={filesUrl.concat(
                          `/products/${devHelper.getObjectValue(
                            brand,
                            "item._values.slug"
                          )}/images/${group}/1.jpg?tr=w-800,v=${img_version}`
                        )}
                        alt={`${name} | ${component.ucfirst(
                          `${devHelper.getObjectValue(
                            type,
                            "item._values.slug"
                          )}-singular`
                        )} | ${devHelper.getObjectValue(
                          brand,
                          "item._values.name"
                        )}`}
                        onLoad={() => {
                          component.setData({ "default.ready": true });
                        }}
                        onError={(e) => {
                          e.target.src = filesUrl.concat(
                            `/assets/images/not-found.jpg`
                          );
                          component.setData({ "default.ready": true });
                        }}
                      />
                    </Box>
                  </Fade>
                </Box>
                <Box sx={{ px: 2, py: 1 }}>
                  <Typography
                    component="h3"
                    sx={{
                      fontSize: 16,
                      color: "#000",
                      fontWeight: 500,
                    }}
                  >
                    {devHelper.getObjectValue(brand, "item._values.name")}
                  </Typography>
                  <Typography
                    component="h3"
                    sx={{ fontSize: 16, color: "#777", fontWeight: 400 }}
                  >
                    {name}
                  </Typography>
                  {price > 0 && (
                    <Typography
                      component="h3"
                      sx={{ fontSize: 16, color: "#ff6363", fontWeight: 500 }}
                    >
                      {first_price > 0 && first_price !== price && (
                        <span style={{ color: "#777", marginRight: "8px" }}>
                          <del>
                            {component.getApp().priceFormater(first_price)}
                          </del>
                        </span>
                      )}
                      <span>{component.getApp().priceFormater(price)}</span>
                    </Typography>
                  )}
                </Box>
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
