import Helpers from "../../../modules/Core/Helpers/Helpers";
import Service from "../Service";

class RecentlyViewed extends Service {
  addToRecentlyViewed(slug) {
    const Env = Helpers.Env;
    const stateHelper = Helpers.State;
    // stateHelper.remove("viewed", {
    //   domain: Env.get("domain"),
    //   path: "/",
    // });

    // const start = Date.now() + 86400000;
    var array = [];

    var viewed = stateHelper.get("viewed", null);
    array = viewed ? viewed.split(",") : [];

    if (array.includes(slug)) {
      array.splice(array.indexOf(slug), 1); // Remove the slug
    }
    if (array.length === 16) {
      array.shift();
    }
    array.push(slug);

    stateHelper.set("viewed", array.join(","), {
      domain: Env.get("domain"),
      // expires: new Date(start),
      path: "/",
    });
  }
}

export default new RecentlyViewed();
