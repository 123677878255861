import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Pages from "../Components/Pages";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MainMessage from "../Components/Widgets/MainMessage";
import LoginRegisterDialog from "../Components/Widgets/Dialogs/LoginRegisterDialog";
import { IKContext } from "imagekitio-react";

const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#000",
    },
  },
  heights: {
    main_desktop: 146,
    main_mobile: 50,
  },
});

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <ThemeProvider theme={theme}>
        <IKContext
          publicKey="public_U4DzzS6SF4i2hEftHZA+vn72iqA="
          urlEndpoint="https://ik.imagekit.io/ruw4c2eph"
          transformationPosition="path"
          authenticationEndpoint="http://www.yourserver.com/auth"
        >
          <Router>
            <CssBaseline />
            <MainMessage />
            <LoginRegisterDialog />
            <Switch>
              <Route exact path="/" component={Pages.Home} />
              <Route
                exact
                path="/assistance/:section"
                component={Pages.Assistance}
              />
              <Route exact path="/company" component={Pages.Company} />
              <Route exact path="/hotels" component={Pages.Hotels} />
              <Route exact path="/stores" component={Pages.Stores} />
              <Route
                exact
                path="/catalogue/:category"
                component={Pages.Category}
              />
              <Route
                exact
                path="/catalogue/:category/:subcategory"
                component={Pages.SubCategory}
              />

              <Route
                exact
                path="/search/results"
                component={Pages.Collection}
              />
              <Route exact path="/wishlist" component={Pages.Wishlist} />
              <Route exact path="/product/:slug" component={Pages.Product} />
              <Route exact path="/cart" component={Pages.Cart} />
              <Route exact path="/checkout" component={Pages.Checkout} />
              {/* user section */}
              <Route exact path="/profile" component={Pages.Profile} />
              <Route exact path="/addresses" component={Pages.Addresses} />
              <Route exact path="/orders" component={Pages.Orders} />
              <Route exact path="/order/:id" component={Pages.Order} />
              <Route exact path="/coupons" component={Pages.Coupons} />
              <Route exact path="/all-brands" component={Pages.Brands} />
              <Route exact path="/brands" component={Pages.B2BBrands} />
              <Route
                exact
                path="/products/:type/:value"
                component={Pages.Collection}
              />
              <Route
                exact
                path="/home/brand/:brand"
                component={Pages.BrandHome}
              />
              <Route exact path="/designers" component={Pages.Designers} />
              {/* <Route exact path="/bzr" component={Pages.Bazaar} /> */}
              <Route component={Pages.Redirect} />
            </Switch>
          </Router>
        </IKContext>
      </ThemeProvider>
    )
  );
};

export default ExportDefault;
